import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame71 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/start/frame71.png"></img>
          <ClickHandler
            top={"20%"}
            left={"6%"}
            width={"88%"}
            height={"12%"}
            router={"/start/frame72"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame71;
