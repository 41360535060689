import React from "react";
import { Stack } from "@mui/material";
import HomeButton from "./HomeButton";
const Layout = ({ children }) => {
  return (
    <Stack position={"relative"}>
      {children}
      <HomeButton />
    </Stack>
  );
};

export default Layout;
