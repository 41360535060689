import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame55 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/setting/frame55-2.png"></img>
          <img src="/assets/setting/frame55-1.png"></img>
          <ClickHandler
            top={"87%"}
            left={"10%"}
            width={"80%"}
            height={"5%"}
            router={"/setting"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame55;
