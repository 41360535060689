import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame2 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/start/frame2.png"></img>
          <ClickHandler
            top={"63%"}
            left={"6%"}
            width={"87%"}
            height={"8%"}
            router={"/start/frame3"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame2;
