import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Operate23 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/operate/operate23.png"></img>
          <ClickHandler
            top={"82%"}
            left={"78%"}
            width={"20%"}
            height={"9%"}
            router={"/operate/operate22"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Operate23;
