import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Step4 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/start/step4.png"></img>
          <ClickHandler
            top={"93%"}
            left={"46%"}
            width={"44%"}
            height={"7%"}
            router={"/start/frame1"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Step4;
