import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame25 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/setting/frame25.png"></img>
          <ClickHandler
            top={"3%"}
            left={"8%"}
            width={"12%"}
            height={"6%"}
            router={"/setting/frame31"}
          />
          <ClickHandler
            top={"20%"}
            left={"5%"}
            width={"70%"}
            height={"10%"}
            router={"/setting/frame25"}
          />
          <ClickHandler
            top={"33%"}
            left={"5%"}
            width={"70%"}
            height={"10%"}
            router={"/setting/frame26"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame25;
