import React from "react";
import { Stack } from "@mui/material";

import Layout from "../components/Layout";

const AutopaymentComplete = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/autopayment-complete.png"></img>
        </Stack>
      }
    ></Layout>
  );
};

export default AutopaymentComplete;
