import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame15 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/setting/frame15.png"></img>
          <ClickHandler
            top={"5%"}
            left={"10%"}
            width={"10%"}
            height={"6%"}
            router={"/setting/frame16"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame15;
