import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Operate22 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/operate/operate22.png"></img>
          <ClickHandler
            top={"1%"}
            left={"5%"}
            width={"15%"}
            height={"7%"}
            router={"/operate/operate21"}
          />
          <ClickHandler
            top={"1%"}
            left={"80%"}
            width={"15%"}
            height={"7%"}
            router={"/operate/operate23"}
          />
          <ClickHandler
            top={"80%"}
            left={"6%"}
            width={"34%"}
            height={"7%"}
            router={"/operate/operate24"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Operate22;
