import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame42 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/start/frame42.png"></img>
          <ClickHandler
            top={"23%"}
            left={"6%"}
            width={"87%"}
            height={"13%"}
            router={"/start/frame41"}
          />
          <ClickHandler
            top={"40%"}
            left={"6%"}
            width={"87%"}
            height={"13%"}
            router={"/start/frame42"}
          />

          <ClickHandler
            top={"90%"}
            left={"6%"}
            width={"87%"}
            height={"8%"}
            router={"/start/frame61"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame42;
