import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const navigate = useNavigate();
  return (
    <Stack
      width={"100%"}
      minHeight={"900px"}
      direction={"column"}
      alignItems={"center"}
      position={"relative"}
    >
      <img
        src="/assets/logo.png"
        width={"100px"}
        height={"100px"}
        style={{ marginTop: "130px" }}
      ></img>
      <Stack my={8} color={"black"} textAlign={"center"}>
        <Typography
          fontFamily={"source-han-sans-k-variable, sans-serif"}
          fontWeight={900}
          fontSize={"30px"}
        >
          법인 기사님은
        </Typography>
        <Typography
          fontFamily={"source-han-sans-k-variable, sans-serif"}
          fontWeight={900}
          fontSize={"30px"}
        >
          차량을 선택해 주셔야
        </Typography>
        <Typography
          fontFamily={"source-han-sans-k-variable, sans-serif"}
          fontWeight={900}
          fontSize={"30px"}
        >
          세팅이 완료돼요.
        </Typography>
      </Stack>
      <Stack direction={"column"} width={"90%"} spacing={5}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "grey",
            },
            fontFamily: "source-han-sans-k-variable, sans-serif",
            fontWeight: 600,
            fontSize: "30px",
          }}
          onClick={() => {
            navigate("/setting-complete");
          }}
        >
          개인택시 기사예요.
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            borderRadius: 3,
            fontFamily: "source-han-sans-k-variable, sans-serif",
            fontWeight: 600,
            fontSize: "30px",
            "&:hover": {
              backgroundColor: "grey",
            },
          }}
          onClick={() => {
            navigate("/setting/frame61");
          }}
        >
          법인택시 기사예요.
        </Button>
      </Stack>
    </Stack>
  );
};

export default Setting;
