import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame13 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/setting/frame13.png"></img>
          <ClickHandler
            top={"80%"}
            left={"10%"}
            width={"80%"}
            height={"8%"}
            router={"/setting/frame14"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame13;
