import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Payment12 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/payment/payment12.png"></img>
        </Stack>
      }
    ></Layout>
  );
};

export default Payment12;
