import React from "react";
import { Stack } from "@mui/material";
import Layout from "../components/Layout";

const SettingComplete = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/setting-complete.png"></img>
        </Stack>
      }
    ></Layout>
  );
};

export default SettingComplete;
