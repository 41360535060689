import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame63 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/start/frame63.png"></img>
          <ClickHandler
            top={"87%"}
            left={"6%"}
            width={"88%"}
            height={"7%"}
            router={"/start/frame71"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame63;
