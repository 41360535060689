import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame3 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/start/frame3.png"></img>
          <ClickHandler
            top={"88%"}
            left={"68%"}
            width={"24%"}
            height={"7%"}
            router={"/start/frame41"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame3;
