import React from "react";
import { Stack } from "@mui/material";
import Layout from "../components/Layout";

const DirectpaymentComplete = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/directpayment-complete.png"></img>
        </Stack>
      }
    ></Layout>
  );
};

export default DirectpaymentComplete;
