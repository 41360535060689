import React from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
const HomeButton = () => {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        position: "absolute",
        bottom: 10,
        right: 10,
        width: "60px",
        cursor: "pointer",
      }}
    >
      <img
        src="/assets/home.png"
        onClick={() => {
          navigate("/");
        }}
      />
    </Stack>
  );
};

export default HomeButton;
