import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Operate21 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/operate/operate21.png"></img>
          <ClickHandler
            top={"78%"}
            left={"5%"}
            width={"15%"}
            height={"7%"}
            router={"/operate/operate22"}
          />
          <ClickHandler
            top={"78%"}
            left={"80%"}
            width={"15%"}
            height={"7%"}
            router={"/operate/operate41"}
          />
          <ClickHandler
            top={"91%"}
            left={"10%"}
            width={"83%"}
            height={"7%"}
            router={"/operate/operate32"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Operate21;
