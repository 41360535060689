import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Operate12 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/operate/operate12.png"></img>
          <ClickHandler
            top={"87%"}
            left={"13%"}
            width={"73%"}
            height={"7%"}
            router={"/operate/operate13"}
          />
        </Stack>
      }
    />
  );
};

export default Operate12;
