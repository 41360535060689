import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./pages/Main";
import Step1 from "./pages/start/Step1";
import Step2 from "./pages/start/Step2";
import Step3 from "./pages/start/Step3";
import Step4 from "./pages/start/Step4";
import Frame1 from "./pages/start/Frame1";
import Frame2 from "./pages/start/Frame2";
import Frame3 from "./pages/start/Frame3";
import Frame41 from "./pages/start/Frame41";
import Frame42 from "./pages/start/Frame42";
import Frame51 from "./pages/start/Frame51";
import Frame52 from "./pages/start/Frame52";
import Frame53 from "./pages/start/Frame53";
import Frame54 from "./pages/start/Frame54";
import Frame55 from "./pages/start/Frame55";
import Frame61 from "./pages/start/Frame61";
import Frame62 from "./pages/start/Frame62";
import Frame63 from "./pages/start/Frame63";
import Frame71 from "./pages/start/Frame71";
import Frame72 from "./pages/start/Frame72";

import Frame11 from "./pages/setting/Frame11";
import Frame12 from "./pages/setting/Frame12";
import Frame13 from "./pages/setting/Frame13";
import Frame14 from "./pages/setting/Frame14";
import Frame15 from "./pages/setting/Frame15";
import Frame16 from "./pages/setting/Frame16";
import Frame17 from "./pages/setting/Frame17";
import Frame18 from "./pages/setting/Frame18";
import Frame21 from "./pages/setting/Frame21";
import Frame22 from "./pages/setting/Frame22";
import Frame23 from "./pages/setting/Frame23";
import Frame24 from "./pages/setting/Frame24";
import Frame25 from "./pages/setting/Frame25";
import Frame26 from "./pages/setting/Frame26";
import Frame31 from "./pages/setting/Frame31";
import Frame32 from "./pages/setting/Frame32";
import Frame33 from "./pages/setting/Frame33";
import Frame34 from "./pages/setting/Frame34";
import Frame35 from "./pages/setting/Frame35";
import Frame41_1 from "./pages/setting/Frame41";
import Frame42_1 from "./pages/setting/Frame42";
import Frame51_1 from "./pages/setting/Frame51";
import Frame52_1 from "./pages/setting/Frame52";
import Frame53_1 from "./pages/setting/Frame53";
import Frame54_1 from "./pages/setting/Frame54";
import Frame55_1 from "./pages/setting/Frame55";
import Frame61_1 from "./pages/setting/Frame61";
import Frame62_1 from "./pages/setting/Frame62";
import Frame63_1 from "./pages/setting/Frame63";
import Frame64 from "./pages/setting/Frame64";
import Frame65 from "./pages/setting/Frame65";
import Frame66 from "./pages/setting/Frame66";
import Frame67 from "./pages/setting/Frame67";
import Frame71_1 from "./pages/setting/Frame71";
import Frame72_1 from "./pages/setting/Frame72";

import Switch11 from "./pages/switch/Switch11";
import Switch12 from "./pages/switch/Switch12";
import Switch13 from "./pages/switch/Switch13";
import Operate11 from "./pages/operate/Operate11";
import Operate12 from "./pages/operate/Operate12";
import Operate13 from "./pages/operate/Operate13";
import Operate14 from "./pages/operate/Operate14";
import Operate21 from "./pages/operate/Operate21";
import Operate22 from "./pages/operate/Operate22";
import Operate23 from "./pages/operate/Operate23";
import Operate24 from "./pages/operate/Operate24";
import Operate31 from "./pages/operate/Operate31";
import Operate32 from "./pages/operate/Operate32";
import Operate33 from "./pages/operate/Operate33";
import Operate34 from "./pages/operate/Operate34";
import Operate35 from "./pages/operate/Operate35";
import Operate41 from "./pages/operate/Operate41";
import Operate42 from "./pages/operate/Operate42";
import Payment11 from "./pages/payment/Payment11";
import Payment12 from "./pages/payment/Payment12";
import Setting from "./pages/Setting";
import CorporateComplete from "./pages/CorporateComplete";
import SettingComplete from "./pages/SettingComplete";
import DirectpaymentComplete from "./pages/DirectpaymentComplete";
import AutopaymentComplete from "./pages/AutopaymentComplete";
import Payment from "./pages/Payment";
import Operate36 from "./pages/operate/Operate36";
import Operate37 from "./pages/operate/Operate37";
import HomeButton from "./components/HomeButton";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/setting-complete" element={<SettingComplete />} />
        <Route path="/corporate-complete" element={<CorporateComplete />} />
        <Route path="/autopayment-complete" element={<AutopaymentComplete />} />
        <Route
          path="/directpayment-complete"
          element={<DirectpaymentComplete />}
        />
        {/* 시작하기 */}
        <Route path="/start/step1" element={<Step1 />} />
        <Route path="/start/step2" element={<Step2 />} />
        <Route path="/start/step3" element={<Step3 />} />
        <Route path="/start/step4" element={<Step4 />} />
        <Route path="/start/frame1" element={<Frame1 />} />
        <Route path="/start/frame2" element={<Frame2 />} />
        <Route path="/start/frame3" element={<Frame3 />} />
        <Route path="/start/frame41" element={<Frame41 />} />
        <Route path="/start/frame42" element={<Frame42 />} />
        <Route path="/start/frame51" element={<Frame51 />} />
        <Route path="/start/frame52" element={<Frame52 />} />
        <Route path="/start/frame53" element={<Frame53 />} />
        <Route path="/start/frame54" element={<Frame54 />} />
        <Route path="/start/frame55" element={<Frame55 />} />
        <Route path="/start/frame61" element={<Frame61 />} />
        <Route path="/start/frame62" element={<Frame62 />} />
        <Route path="/start/frame63" element={<Frame63 />} />
        <Route path="/start/frame71" element={<Frame71 />} />
        <Route path="/start/frame72" element={<Frame72 />} />

        {/* 필수설정 */}
        <Route path="/setting/frame11" element={<Frame11 />} />
        <Route path="/setting/frame12" element={<Frame12 />} />
        <Route path="/setting/frame13" element={<Frame13 />} />
        <Route path="/setting/frame14" element={<Frame14 />} />
        <Route path="/setting/frame15" element={<Frame15 />} />
        <Route path="/setting/frame16" element={<Frame16 />} />
        <Route path="/setting/frame17" element={<Frame17 />} />
        <Route path="/setting/frame18" element={<Frame18 />} />

        <Route path="/setting/frame21" element={<Frame21 />} />
        <Route path="/setting/frame22" element={<Frame22 />} />
        <Route path="/setting/frame23" element={<Frame23 />} />
        <Route path="/setting/frame24" element={<Frame24 />} />
        <Route path="/setting/frame25" element={<Frame25 />} />
        <Route path="/setting/frame26" element={<Frame26 />} />

        <Route path="/setting/frame31" element={<Frame31 />} />
        <Route path="/setting/frame32" element={<Frame32 />} />
        <Route path="/setting/frame33" element={<Frame33 />} />
        <Route path="/setting/frame34" element={<Frame34 />} />
        <Route path="/setting/frame35" element={<Frame35 />} />

        <Route path="/setting/frame41" element={<Frame41_1 />} />
        <Route path="/setting/frame42" element={<Frame42_1 />} />

        <Route path="/setting/frame51" element={<Frame51_1 />} />
        <Route path="/setting/frame52" element={<Frame52_1 />} />
        <Route path="/setting/frame53" element={<Frame53_1 />} />
        <Route path="/setting/frame54" element={<Frame54_1 />} />
        <Route path="/setting/frame55" element={<Frame55_1 />} />

        <Route path="/setting/frame61" element={<Frame61_1 />} />
        <Route path="/setting/frame62" element={<Frame62_1 />} />
        <Route path="/setting/frame63" element={<Frame63_1 />} />
        <Route path="/setting/frame64" element={<Frame64 />} />
        <Route path="/setting/frame65" element={<Frame65 />} />
        <Route path="/setting/frame66" element={<Frame66 />} />
        <Route path="/setting/frame67" element={<Frame67 />} />

        <Route path="/setting/frame71" element={<Frame71_1 />} />
        <Route path="/setting/frame72" element={<Frame72_1 />} />

        {/* 온오프라인 전환 */}
        <Route path="/switch/switch11" element={<Switch11 />} />
        <Route path="/switch/switch12" element={<Switch12 />} />
        <Route path="/switch/switch13" element={<Switch13 />} />

        {/* 운행하기 */}
        <Route path="/operate/operate11" element={<Operate11 />} />
        <Route path="/operate/operate12" element={<Operate12 />} />
        <Route path="/operate/operate13" element={<Operate13 />} />
        <Route path="/operate/operate14" element={<Operate14 />} />

        <Route path="/operate/operate21" element={<Operate21 />} />
        <Route path="/operate/operate22" element={<Operate22 />} />
        <Route path="/operate/operate23" element={<Operate23 />} />
        <Route path="/operate/operate24" element={<Operate24 />} />

        <Route path="/operate/operate31" element={<Operate31 />} />
        <Route path="/operate/operate32" element={<Operate32 />} />
        <Route path="/operate/operate33" element={<Operate33 />} />
        <Route path="/operate/operate34" element={<Operate34 />} />
        <Route path="/operate/operate35" element={<Operate35 />} />
        <Route path="/operate/operate36" element={<Operate36 />} />
        <Route path="/operate/operate37" element={<Operate37 />} />

        <Route path="/operate/operate41" element={<Operate41 />} />
        <Route path="/operate/operate42" element={<Operate42 />} />

        {/* 직접 결제 */}
        <Route path="/payment/payment11" element={<Payment11 />} />
        <Route path="/payment/payment12" element={<Payment12 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
