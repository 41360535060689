import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Operate35 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/operate/operate35.png"></img>
          <ClickHandler
            top={"88%"}
            left={"8%"}
            width={"83%"}
            height={"8%"}
            router={"/autopayment-complete"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Operate35;
