import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame41 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/setting/frame41.png"></img>
          <ClickHandler
            top={"2%"}
            left={"8%"}
            width={"10%"}
            height={"6%"}
            router={"/setting/frame51"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame41;
