import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Operate31 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/operate/operate31.png"></img>
          <ClickHandler
            top={"88%"}
            left={"10%"}
            width={"83%"}
            height={"8%"}
            router={"/operate/operate32"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Operate31;
