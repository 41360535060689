import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Step3 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/start/step3.png"></img>
          <ClickHandler
            top={"40%"}
            left={"40%"}
            width={"20%"}
            height={"10%"}
            router={"/start/step4"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Step3;
