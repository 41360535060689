import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeButton from "../components/HomeButton";

const Main = () => {
  const navigate = useNavigate();
  return (
    <Stack
      width={"100%"}
      minHeight={"900px"}
      direction={"column"}
      alignItems={"center"}
      position={"relative"}
    >
      <img
        src="/assets/logo.png"
        width={"100px"}
        height={"100px"}
        style={{ marginTop: "130px" }}
      ></img>
      <Stack my={8} color={"black"} textAlign={"center"}>
        <Typography
          fontFamily={"source-han-sans-k-variable, sans-serif"}
          fontWeight={900}
          fontSize={"30px"}
        >
          우버 기사님용 앱
        </Typography>
        <Typography
          fontFamily={"source-han-sans-k-variable, sans-serif"}
          fontWeight={900}
          fontSize={"30px"}
        >
          사용법 익히기
        </Typography>
      </Stack>
      <Stack direction={"column"} width={"90%"} spacing={3}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "grey",
            },
            fontFamily: "source-han-sans-k-variable, sans-serif",
            fontWeight: 600,
            fontSize: "30px",
          }}
          onClick={() => {
            navigate("/start/step1");
          }}
        >
          시작하기
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            borderRadius: 3,
            fontFamily: "source-han-sans-k-variable, sans-serif",
            fontWeight: 600,
            fontSize: "30px",
            "&:hover": {
              backgroundColor: "grey",
            },
          }}
          onClick={() => {
            navigate("/setting/frame11");
          }}
        >
          필수설정
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            borderRadius: 3,
            fontFamily: "source-han-sans-k-variable, sans-serif",
            fontWeight: 600,
            fontSize: "30px",
            "&:hover": {
              backgroundColor: "grey",
            },
          }}
          onClick={() => {
            navigate("/switch/switch11");
          }}
        >
          출/퇴근하기
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            borderRadius: 3,
            fontFamily: "source-han-sans-k-variable, sans-serif",
            fontWeight: 600,
            fontSize: "30px",
            "&:hover": {
              backgroundColor: "grey",
            },
          }}
          onClick={() => {
            navigate("/operate/operate11");
          }}
        >
          운행하기
        </Button>
      </Stack>

      <HomeButton />
    </Stack>
  );
};

export default Main;
