import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame11 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/setting/frame11.png"></img>
          <ClickHandler
            top={"72%"}
            left={"40%"}
            width={"20%"}
            height={"9%"}
            router={"/setting/frame12"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame11;
