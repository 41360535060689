import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Frame34 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/setting/frame34.png"></img>
          <ClickHandler
            top={"40%"}
            left={"40%"}
            width={"20%"}
            height={"10%"}
            router={"/setting/frame35"}
          />

          <ClickHandler
            top={"77%"}
            left={"5%"}
            width={"70%"}
            height={"8%"}
            router={"/setting/frame34"}
          />

          <ClickHandler
            top={"89%"}
            left={"5%"}
            width={"70%"}
            height={"8%"}
            router={"/setting/frame33"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Frame34;
