import { Stack } from "@mui/material";
import React from "react";
import ClickHandler from "../../components/ClickHandler";
import Layout from "../../components/Layout";

const Switch13 = () => {
  return (
    <Layout
      children={
        <Stack position={"relative"}>
          <img src="/assets/switch/switch13.png"></img>
          <ClickHandler
            top={"80%"}
            left={"40%"}
            width={"20%"}
            height={"9%"}
            router={"/"}
          />
        </Stack>
      }
    ></Layout>
  );
};

export default Switch13;
