import { Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ClickHandler = ({ top, left, width, height, router }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "absolute",
        top: top,
        left: left,
        width: width,
        height: height,
        cursor: "pointer",
        backgroundColor: "#FF8956",
        transition: "all 0.3s ease",
        filter: "blur(0px)",
        animation: "pulse 1s infinite",

        "&:hover": {
          backgroundColor: "#cccccc44",
          filter: "blur(10px)",
        },

        "@keyframes pulse": {
          "0%": {
            backgroundColor: "#ff8956b9",
          },
          "50%": {
            backgroundColor: "rgba(255, 137, 86, 0.3)",
          },
          "100%": {
            backgroundColor: "#ff8956b9",
          },
        },
      }}
      onClick={() => {
        navigate(router);
      }}
    />
  );
};

export default ClickHandler;
